import React from "react"
import "../styles/karuzela.scss"
import Slider from "react-slick"
import { graphql, Link, useStaticQuery } from "gatsby"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const Karuzela = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulKaruzela {
        edges {
          node {
            tytul
            podtytul
            grafika {
              fluid(maxWidth: 2300) {
                src
              }
            }
            url
          }
        }
      }
    }
  `)
  const settings = {
    dots: true,
    infinite: true,
    autoplaySpeed: 5000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  return (

    <div className="content">
      <Slider {...settings}>
        {data.allContentfulKaruzela.edges.map((node, id) => (
          <div key={`${node}-${id}`} className="carousel-content-wrapper">
            <Link to={`/${node?.node?.url}`}>
              <div className="carousel-text-wrapper">
                <h2 className="baner-tytul">{node?.node?.tytul}</h2>
                <p className="baner-podtytul">{node?.node?.podtytul}</p>
              </div>
            </Link>
            <img
              key={`img-${node}-${id}`}
              style={{
                height: "440px",
                width: "100%",
                backgroundImage: `url(${node?.node?.grafika?.fluid?.src})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                objectFit: "cover",
              }}
              alt=""
            />
          </div>
        ))}
      </Slider>
    </div>
  )
}
export default Karuzela
