import React from "react"
import "../styles/main.scss"
import _navigation from "../components/_navigation"
import Footer from "../components/footer"
import Karuzela from "../components/karuzela"
import Filozofia from "../components/filozofia"
import Galeria from "../components/galeria"
import Lekarze from "../components/lekarze"
import KategoriaLista from "../components/kategorieLista"
import config from "react-reveal/globals"
import { Fade } from "react-reveal"
import { Helmet } from "react-helmet"

const Index = () => {
  config({ ssrFadeout: true })
  const filozofia = "Nasza filozofia"
  const lokal = "Lokal"
  const opisLokal =
    "Zapraszamy do komfortowo urządzonych gabinetów Laser-Medica. Naszym usługom towarzyszą zawsze wygoda, higiena i przyjemna atmosfera."
  const opisFilozofia =
    "Oferujemy wysokiej jakości usługi stawiając na bezpieczeństwo i zaufanie. Stosujemy sprawdzone urządzenia i kosmetyki. Naszą ambicją jest aby nasi klienci zostali z nami na dłużej."
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Laser-Medica Szczecin</title>
        <meta
          name="description"
          content="Dermatologia Laserowa i Estetyczna, Depilacja Laserowa. Zabiegi dermatochirurgiczne oraz ginekologia estetyczna. Szeroka oferta zabiegów dermatologicznych."
        />
        <link rel="canonical" href="https://laser-medica.szczecin.pl" />
      </Helmet>
      <_navigation />
      <Fade ssrFadeout>
        <Karuzela />
      </Fade>
      <Fade ssrFadeout delay={500}>
        <Filozofia title={filozofia} opis={opisFilozofia} />
      </Fade>
      <Fade ssrFadeout>
        <KategoriaLista />
      </Fade>
      <Fade ssrFadeout>
        <Filozofia title={lokal} opis={opisLokal} />
      </Fade>
      <Fade ssrFadeout>
        <Galeria />
      </Fade>
      <Lekarze />
      <Footer />
    </div>
  )
}
export default Index
