import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import "../styles/kategorieLista.scss"
import "bulma/css/bulma.css"
import KategoriaBtn from "./kategoriaBtn"

const KategoriaLista = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulStronaKategoriaGlowna {
        edges {
          node {
            tytul
            slug
          }
        }
      }
      allContentfulNawigacjaMenu {
        edges {
          node {
            kategoriaGlowna
            podkategorie
            slug
          }
        }
      }
    }
  `)
  const nullValuesClearData = data.allContentfulStronaKategoriaGlowna.edges.filter(
    node => node.node.tytul !== null
  )
  const nullValuesClearNawigacja = data.allContentfulNawigacjaMenu.edges.map(
    node =>
      node.node.podkategorie !== null &&
      node.node.kategoriaGlowna !== null &&
      node
  )
  return (
    <div className="kategorie-container">
      <div className="top-part-lista-container container">
        <div className="kategorie-linki-flex columns">
          <div className="lista-wrapper column auto">
            {nullValuesClearData.map((kategoria, id) => {
              const l = nullValuesClearNawigacja.filter(
                node => node !== false && node.slug === kategoria.slug
              )
              const x = l.map(n => n.node)
              const modulo = nullValuesClearData.length % 3
              const moduloCheck =
                modulo === 0
                  ? Math.floor(nullValuesClearData.length / 3)
                  : Math.floor(nullValuesClearData.length / 3 + modulo)
              return (
                id < moduloCheck && (
                  <KategoriaBtn
                    key={`${kategoria}-key-${id}`}
                    kategoria={kategoria}
                    podkategorie={x}
                  />
                )
              )
            })}
          </div>
          <div className="lista-wrapper column auto">
            {nullValuesClearData.map((kategoria, id) => {
              const l = nullValuesClearNawigacja.filter(
                node => node !== false && node.slug === kategoria.slug
              )
              const x = l.map(n => n.node)
              const modulo = Math.ceil(nullValuesClearData.length % 3)
              const moduloCheck =
                modulo === 0
                  ? Math.floor(nullValuesClearData.length / 3) * 2
                  : Math.floor(nullValuesClearData.length / 3 + modulo) * 2
              return (
                id >= Math.floor(nullValuesClearData.length / 3 + modulo) &&
                id < moduloCheck && (
                  <KategoriaBtn
                    key={`${kategoria}-key-${id}`}
                    kategoria={kategoria}
                    podkategorie={x}
                  />
                )
              )
            })}
          </div>
          <div className="lista-wrapper column auto">
            {nullValuesClearData.map((kategoria, id) => {
              const l = nullValuesClearNawigacja.filter(
                node => node !== false && node.slug === kategoria.slug
              )
              const x = l.map(n => n.node)
              const modulo = Math.ceil(nullValuesClearData.length % 3)
              const moduloCheck =
                modulo === 0
                  ? Math.floor(nullValuesClearData.length / 3) * 3
                  : Math.floor(nullValuesClearData.length / 3 + modulo) * 3
              return (
                id >= Math.floor(nullValuesClearData.length / 3 + modulo) * 2 &&
                id < moduloCheck && (
                  <KategoriaBtn
                    key={`${kategoria}-key-${id}`}
                    kategoria={kategoria}
                    podkategorie={x}
                  />
                )
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
export default KategoriaLista
